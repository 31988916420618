import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Components & UI
import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';

import AddAddressForm, {
    AddressFormSchema,
    AddressVerificationFailureModalContent
} from 'components/add-address-form/AddAddressForm';
import AutoRefillBody from 'components/auto-refill-body/auto-refill-body';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import AddressCard from 'components/shipping-addresses/address-card/address-card.component';
import { ShippingAddressesProps } from 'components/shipping-addresses/shipping-addresses.props';

import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

import {
    accountAddAddressToProfileRoutine,
    accountRemoveAddressFromProfileRoutine,
    accountSetPrimaryAddressRoutine,
    accountUpdateAddressToProfileRoutine
} from 'state/account/account.routines';
import {
    accountAutoRefillEligibleSelector,
    accountIsCaliforniaUserSelector,
    accountProfileAddressesSelector
} from 'state/account/account.selectors';
import { ProfileObjectAddressPayload } from 'state/account/account.services';
// Redux & Services
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
// State
import {
    easyRefillAddAddressesRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillSetPrimaryAddressRoutine,
    easyRefillUpdateAddressesRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    easyRefillAddressesSelector,
    easyRefillEpostPatientNumSelector
} from 'state/easy-refill/easy-refill.selectors';
import { AddressParts } from 'state/usps/usps.reducers';

import { validateAutoRefillModalDisplay } from 'util/autorefill';
// Utils
import storageHelper from 'util/storageHelper';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';

// Types
import { AddressCardProps } from './address-card/address-card.props';
// Types
import './shipping-addresses.style.scss';

const DeleteAddressModalContent = ({
    address,
    translation
}: {
    address: AddressCardProps;
    translation: TFunction<'translation'>;
}) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.changeAddressModal.body', {
                address: `<strong>${address.address1}, ${address.city}, ${address.state} ${address.zipcode}</strong>`
            })}
        />
    );
};

const ShippingAddresses: FC<ShippingAddressesProps> = ({
    addressData,
    onSetAsShipping,
    isProfile,
    showLabels = true,
    showActions = true,
    addNewAddressButtonLabel,
    isEasyRefill
}: ShippingAddressesProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isAddressBusy, setIsAddressBusy] = useState(false);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);

    // EasyRefill Data
    const easyRefillepostPatientNum = useSelector(easyRefillEpostPatientNumSelector);
    const easyRefillAddresses = useSelector(easyRefillAddressesSelector);

    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();
    const sortDefaultAddresses = useMemo(() => {
        // Add defaultAddress boolean to first address automatically to be addressed in DRX-115
        let addressesToSort = [...addressData];

        return addressesToSort.sort((a, b) => Number(b.defaultAddress) - Number(a.defaultAddress));
    }, [addressData]);

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };

    const closeModalHandler = () => {
        dispatch(closeModal({}));
        storageHelper.local.setAutoRefillToggleFlag();
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.addAddressModal.title')}
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const showAutoRefillModal = useCallback(
        (state: string) => {
            if (validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident, state)) {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'scroll-modal',
                        onClose: closeModalHandler,
                        bodyContent: (
                            <BirdiModalContent
                                icon={'none'}
                                title={t(`pages.autoRefill.userVerification.title`)}
                                body={<AutoRefillBody isModal onClose={closeModalHandler} />}
                            />
                        ),
                        ctas: []
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAutoRefillEligible, isCAResident]
    );

    const handleAddAddressFormSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                setIsAddressBusy(true);
                if (isEasyRefill) {
                    dispatch(
                        easyRefillAddAddressesRoutine.trigger({
                            ...values,
                            epostPatientNum: easyRefillepostPatientNum,
                            zipcode: Zip5,
                            zipcodeFour: Zip4,
                            onSuccess: () => {
                                setIsAddressBusy(false);
                                dispatch(closeModal({}));
                                dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                            },
                            onFailure: () => {
                                dispatch(closeModal({}));
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        type: 'danger',
                                        size: 'lg',
                                        headerContent: (
                                            <BirdiModalHeaderDanger
                                                icon="alert"
                                                headerText={t('modals.updateProfile.error')}
                                            />
                                        ),
                                        bodyContent: (
                                            <FailureUpdateProfileModalContent
                                                area={t('modals.updateProfile.areas.address')}
                                            />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateProfile.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                },
                                                dataGALocation: 'UpdateProfileError'
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                } else {
                    dispatch(
                        accountAddAddressToProfileRoutine.trigger({
                            ...values,
                            zipcode: Zip5,
                            zipcodeFour: Zip4,
                            onSuccess: () => {
                                dispatch(closeModal({}));
                                setIsAddressBusy(false);

                                if (values.defaultAddress) {
                                    showAutoRefillModal(address.state);
                                }
                            },
                            onFailure: () => {
                                dispatch(closeModal({}));
                                setIsAddressBusy(false);
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        type: 'danger',
                                        size: 'lg',
                                        headerContent: (
                                            <BirdiModalHeaderDanger
                                                icon="alert"
                                                headerText={t('modals.updateProfile.error')}
                                            />
                                        ),
                                        bodyContent: (
                                            <FailureUpdateProfileModalContent
                                                area={t('modals.updateProfile.areas.address')}
                                            />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateProfile.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                },
                                                dataGALocation: 'UpdateProfileError'
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                }
            },
            onFailure: ({ response, errors }) => {
                setIsAddressBusy(false);
                if (values.onFailure && errors && !errors.error) {
                    values.onFailure(errors);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addressVerificationFailure.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handleAddNewAddressClick(values);
                                    },
                                    dataGALocation: 'AddressNotVerified'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const handleShippingAddressChange = (address: AddressCardProps) => {
        if (isEasyRefill) {
            const setAsDefaultAddress = easyRefillAddresses.find(
                (easyRefillAddress) => easyRefillAddress.addressSeqNum === address.addressSeqNum
            );

            const newDefaultAddress = {
                ...setAsDefaultAddress,
                defaultShip: true,
                currentShipping: true,
                defaultBill: true
            };

            setIsAddressBusy(true);
            dispatch(
                easyRefillSetPrimaryAddressRoutine({
                    address: newDefaultAddress,
                    onSuccess: () => {
                        setIsAddressBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: isProfile ? (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                ) : (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.updateProfile.title')}
                                        body={t('modals.updateCart.defaultAddressUpdated')}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                                        },
                                        dataGALocation: t('modals.updateProfile.title')
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                                        },
                                        dataGALocation: 'UpdateProfileError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        } else {
            setIsAddressBusy(true);
            dispatch(
                accountSetPrimaryAddressRoutine({
                    ...address,
                    onSuccess: () => {
                        setIsAddressBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: isProfile ? (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                ) : (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.updateProfile.title')}
                                        body={t('modals.updateCart.defaultAddressUpdated')}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            showAutoRefillModal(address.state);
                                        },
                                        dataGALocation: t('modals.updateProfile.title')
                                    }
                                ],
                                onClose: () => {
                                    dispatch(closeModal({}));
                                    showAutoRefillModal(address.state);
                                }
                            })
                        );
                    },
                    onFailure: () => {
                        setIsAddressBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'UpdateProfileError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    };

    const handleOpenDeleteModal = (address: AddressCardProps) => {
        dispatch(
            openModal({
                showClose: true,
                size: 'lg',
                type: 'danger',
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.changeAddressModal.title')} icon="alert" />
                ),
                bodyContent: <DeleteAddressModalContent address={address} translation={t} />,
                ctas: [
                    {
                        label: 'Confirm',
                        variant: 'primary',
                        onClick: () => {
                            setIsAddressBusy(true);
                            dispatch(
                                accountRemoveAddressFromProfileRoutine({
                                    address: address,
                                    onSuccess: () => {
                                        dispatch(closeModal({}));
                                        setIsAddressBusy(false);
                                    },
                                    onFailure: () => {
                                        setIsAddressBusy(false);
                                        dispatch(
                                            openModal({
                                                showClose: true,
                                                size: 'lg',
                                                type: 'danger',
                                                headerContent: (
                                                    <BirdiModalHeaderDanger
                                                        icon="alert"
                                                        headerText={t('modals.updateProfile.error')}
                                                    />
                                                ),
                                                bodyContent: (
                                                    <FailureUpdateProfileModalContent
                                                        area={t('modals.updateProfile.areas.address')}
                                                    />
                                                ),
                                                ctas: [
                                                    {
                                                        label: t('modals.updateProfile.labels.gotIt'),
                                                        variant: 'primary',
                                                        onClick: () => {
                                                            dispatch(closeModal({}));
                                                        }
                                                    }
                                                ]
                                            })
                                        );
                                    }
                                })
                            );
                        },
                        dataGALocation: t('modals.changeAddressModal.title')
                    }
                ]
            })
        );
    };

    const handleEditAddressClick = (address: AddressCardProps) => {
        const editAddress: AddressFormSchema = !address.zipcodeFour
            ? { ...address, addressTypeDesc: address.addressType }
            : {
                  ...address,
                  zipcode: `${address.zipcode}-${address.zipcodeFour}`,
                  addressTypeDesc: address.addressType
              };

        let editAddressIndex: number | Number;
        if (isEasyRefill) {
            editAddressIndex = easyRefillAddresses.findIndex(
                (easyRefillAddress) => easyRefillAddress.addressSeqNum === address.addressSeqNum
            );
        } else {
            editAddressIndex = profileAddresses.findIndex(
                (profileAddress) => profileAddress.addressSeqNum === address.addressSeqNum
            );
        }

        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.editAddressModal.title')}
                                defaultValues={editAddress}
                                handleFormCancel={() => {}}
                                handleFormSubmit={(values) => {
                                    handleEditAddressSubmit(values, editAddressIndex);
                                }}
                                centerFormSubmit={true}
                                showCancel={false}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleEditAddressSubmit = (values: ProfileObjectAddressPayload, editAddressIndex: Number) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };

        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                const address = { ...values, zipcode: Zip5, zipcodeFour: Zip4 };
                const updateAddress = {
                    ...values,
                    zipcode: Zip5,
                    zipcodeFour: Zip4,
                    epostPatientNum: easyRefillepostPatientNum
                };

                if (isEasyRefill) {
                    dispatch(
                        easyRefillUpdateAddressesRoutine({
                            ...updateAddress,
                            index: editAddressIndex,
                            onSuccess: () => {
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        bodyContent: (
                                            <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateProfile.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                    dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                                                }
                                            }
                                        ]
                                    })
                                );
                            },
                            onFailure: () => {
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        size: 'lg',
                                        type: 'danger',
                                        headerContent: (
                                            <BirdiModalHeaderDanger
                                                headerText={t('modals.updateProfile.error')}
                                                icon="alert"
                                            />
                                        ),
                                        bodyContent: (
                                            <FailureUpdateProfileModalContent
                                                area={t('modals.updateProfile.areas.address')}
                                            />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateProfile.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                } else {
                    dispatch(
                        accountUpdateAddressToProfileRoutine({
                            index: editAddressIndex,
                            address,
                            onSuccess: () => {
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        bodyContent: (
                                            <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateProfile.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            }
                                        ]
                                    })
                                );
                            },
                            onFailure: () => {
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        size: 'lg',
                                        type: 'danger',
                                        headerContent: (
                                            <BirdiModalHeaderDanger
                                                headerText={t('modals.updateProfile.error')}
                                                icon="alert"
                                            />
                                        ),
                                        bodyContent: (
                                            <FailureUpdateProfileModalContent
                                                area={t('modals.updateProfile.areas.address')}
                                            />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateProfile.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                }
            },
            onFailure: ({ response, errors }) => {
                if (values.onFailure && errors && !errors.error) {
                    values.onFailure(errors);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    return (
        <>
            <Spinner isVisible={isAddressBusy} t={t} />
            {sortDefaultAddresses.length === 0 && (
                <Container fluid className="my-4">
                    <p>{t('pages.profile.shippingAddress.empty')}</p>
                </Container>
            )}
            {sortDefaultAddresses.length > 0 && (
                <Container fluid>
                    <Row xs={1} md={1} lg={2} xl={2} className="gy-3 d-flex shipping-addresses px-3">
                        {sortDefaultAddresses.map((value, index) => {
                            return (
                                <AddressCard
                                    key={index}
                                    index={index}
                                    defaultAddress={value.defaultAddress}
                                    address1={value.address1}
                                    address2={value.address2}
                                    city={value.city}
                                    state={value.state}
                                    zipcode={value.zipcode}
                                    zipcodeFour={value.zipcodeFour}
                                    isChecked={value.isChecked}
                                    openDeleteModal={() => handleOpenDeleteModal(value)}
                                    openEdit={() => handleEditAddressClick(value)}
                                    addressType={value.addressType}
                                    isProfile={isProfile}
                                    showActions={showActions}
                                    showLabel={showLabels}
                                    onSetAsDefault={() => handleShippingAddressChange(value)}
                                    handleSetAsShipping={onSetAsShipping ? () => onSetAsShipping(value) : undefined}
                                />
                            );
                        })}
                    </Row>
                </Container>
            )}
            {addNewAddressButtonLabel && !isProfile && (
                <div className="add-address-btn-container">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="sm-full text-uppercase p-2 btn-bold"
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant="text-blue"
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                    />
                </div>
            )}
            {addNewAddressButtonLabel && isProfile && (
                <div className="add-address-btn-container">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="sm-full btn-bold"
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant="text-blue"
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                    />
                </div>
            )}
        </>
    );
};

export default ShippingAddresses;
