import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import BirdiAccordion from 'ui-kit/accordion/accordion';
import Button from 'ui-kit/button/button';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/easy-refill/health-conditions/easy-refill-health-profile.component';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { getPhoneNumber } from 'util/globalVariables';

import './health-conditions.style.scss';

interface HealthConditionsProps {
    isUserHasNotSubmittedConditions?: boolean;
    existingConditions: string[];
    existingAllergies: string[];
    isUserHasNotSubmittedAllergies: boolean;
}

interface HealthConditionsButtonProps {
    onClick: () => void;
    label: string;
}

export const HealthConditionsWarningModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.prescription.addAllergy.subTitle', {
                phoneNumber: getPhoneNumber({})
            })}
        />
    );
};

const HealthConditionsAddConditionButton = ({ label, onClick }: HealthConditionsButtonProps) => (
    <div className="cart__health-conditions__link">
        <Button
            plusIcon
            IconType="secondary"
            className="p-0"
            dataGAFormName="healthConditions"
            label={label}
            onClick={onClick}
            type="button"
            variant="text-blue"
        />
    </div>
);

const HealthConditionsAddAllergiesButton = ({ label, onClick }: HealthConditionsButtonProps) => (
    <div className="cart__health-conditions__link">
        <Button
            plusIcon
            IconType="secondary"
            className="p-0"
            dataGAFormName="allergies"
            label={label}
            onClick={onClick}
            type="button"
            variant="text-blue"
        />
    </div>
);

const HealthConditions = ({
    isUserHasNotSubmittedConditions,
    existingConditions,
    existingAllergies,
    isUserHasNotSubmittedAllergies
}: HealthConditionsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'primary',
                size: 'lg',
                className: 'modal-health-profile-warning',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        },
                        dataGALocation: 'ReviewOrderHealthConditions'
                    }
                ]
            })
        );
    };
    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    return (
        <div className="cart__health-conditions">
            <Container fluid>
                <Row>
                    <Col>
                        <div
                            className="cart__health-conditions__text"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.reviewOrder.healthProfileConfirmation', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="cart__health-conditions__title">{t('accordions.healthConditions.title')}</h4>
                    </Col>
                    <Col className="text-right d-none d-lg-flex justify-content-lg-end">
                        <HealthConditionsAddConditionButton
                            label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                            onClick={handleToggleHealthConditionsClick}
                        />
                    </Col>
                </Row>
                {existingConditions.length > 0 && (
                    <Row>
                        <Col>
                            <HealthConditionPills conditions={existingConditions} />
                        </Col>
                    </Row>
                )}
                {isUserHasNotSubmittedConditions && (
                    <Row className="easy_refill__health-conditions__errors">
                        <Col>
                            <div className="error">{t('pages.easyRefill.review.errors.conditionsRequired')}</div>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center d-block d-lg-none">
                        <HealthConditionsAddConditionButton
                            label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                            onClick={handleToggleHealthConditionsClick}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BirdiAccordion.Spacer />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="cart__health-conditions__title">{t('accordions.allergies.title')}</h4>
                    </Col>
                    <Col className="text-right d-none d-lg-flex justify-content-lg-end">
                        <HealthConditionsAddAllergiesButton
                            label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                            onClick={handleToggleAllergiesClick}
                        />
                    </Col>
                </Row>
                {existingAllergies.length > 0 && (
                    <Row className="mt-1">
                        <Col>
                            <HealthConditionPills conditions={existingAllergies} />
                        </Col>
                    </Row>
                )}
                {isUserHasNotSubmittedAllergies && (
                    <Row className="easy_refill__health-conditions__errors_allergies">
                        <Col>
                            <div className="error">{t('pages.easyRefill.review.errors.allergiesRequired')}</div>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center d-block d-lg-none">
                        <HealthConditionsAddAllergiesButton
                            label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                            onClick={handleToggleAllergiesClick}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HealthConditions;
