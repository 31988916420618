import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'ui-kit/button/button';

import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';

import { convertToTitleCase } from 'util/string';

import './address-card.style.scss';

//
// --- Helper Functions ---

function formatAddressType(addressType: string) {
    return addressType
        .split('')
        .map((char, i) => (i === 0 ? char : char.toLowerCase()))
        .join('');
}

//
// --- AddressCard Component ---

const AddressCard: React.FC<AddressCardProps> = ({
    defaultAddress,
    address1,
    address2,
    city,
    state,
    zipcode,
    zipcodeFour,
    openDeleteModal,
    openEdit,
    isChecked,
    index,
    handleSetAsShipping,
    onSetAsDefault,
    addressType,
    isProfile,
    showLabel = true,
    showActions = true
}) => {
    const { t } = useTranslation();

    return (
        <Col className="address-card__wrapper flex-fill">
            <div className="address-card-content border rounded rounded-2 border-sea-foam h-100">
                <Row className="no-gutters">
                    {handleSetAsShipping && (
                        <Col xs={'auto'}>
                            <section className="address-card--default-address">
                                <label className="default-address text-slate d-flex mb-0">
                                    <input
                                        type="radio"
                                        name="option"
                                        id={`address-${index}`}
                                        onChange={handleSetAsShipping}
                                        checked={isChecked}
                                    />
                                </label>
                            </section>
                        </Col>
                    )}
                    <Col>
                        <div className="address-card d-flex flex-column">
                            <div className="address-card__header">
                                <div className="h6 d-flex address-card__eyebrow">
                                    {addressType && showLabel && (
                                        <p className="address-card__type text-left mb-0">
                                            <small>
                                                {t('pages.profile.shippingAddress.addressType', {
                                                    type: formatAddressType(addressType)
                                                })}
                                            </small>
                                        </p>
                                    )}
                                </div>
                                <div className="address-card--content h-100">
                                    <div className="align-self-start">
                                        <div className="h6 hero-eyebrow-text">
                                            {convertToTitleCase(address1)}{' '}
                                            {address2 ? convertToTitleCase(address2) : ''}
                                        </div>
                                        <div className="h6 hero-eyebrow-text">
                                            {convertToTitleCase(city)}, {state}{' '}
                                            {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showActions && (
                                <div className="address-card__actions">
                                    <Button
                                        className="address-card__action no-min-width pl-0 pr-1 pt-1 pb-1"
                                        variant="text"
                                        type="button"
                                        onClick={openEdit}
                                        dataGAFormName="address-card"
                                        label={t('shipping.editAddress')}
                                    />
                                    {isProfile && !defaultAddress && (
                                        <>
                                            <span className="divider">|</span>
                                            <Button
                                                className="address-card__action no-min-width p-1"
                                                variant="text"
                                                type="button"
                                                onClick={openDeleteModal}
                                                dataGAFormName="address-card"
                                                label={t('shipping.removeAddress')}
                                            />
                                        </>
                                    )}
                                    <span className="divider">|</span>
                                    {defaultAddress ? (
                                        <span className="strong">{t('shipping.defaultAddressLabel')}</span>
                                    ) : (
                                        onSetAsDefault && (
                                            <Button
                                                className="address-card__action no-min-width p-1"
                                                variant="text"
                                                type="button"
                                                onClick={onSetAsDefault}
                                                dataGAFormName="address-card"
                                                label={t('shipping.setAsDefault')}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default AddressCard;
